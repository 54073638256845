<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <LocationRemarksSelection
                  @getLocationRemarks="getLocationRemarks"
                  :lrFilter="locationRemarksFilter"
                  :lrIdsFilter="locationRemarksIdsFilter"/>
              <v-dialog v-model="dialog" fullscreen
                        transition="dialog-bottom-transition">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn color="#00004d" dark class="ml-auto ma-3 mr-1" @click="refreshData">
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn color="#00004d" dark class="ma-3 ml-0" v-on="on">
                      Add
                      <v-icon small>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span v-if="editedItem.id">Edit {{ editedItem.title }}</span>
                      <span v-else>Create Development Detail</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card-text>
                    <v-form
                        ref="form"
                        v-model="validationRules.valid"
                        lazy-validation
                    >
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.title"
                                        maxLength="100"
                                        :counter="100" :rules="validationRules.titleRules"
                                        label="Title*"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                              clearable
                              outlined
                              dense
                              :items="propertyTypesFilter"
                              v-model="editedItem.property_type_id"
                              item-text="full_title"
                              item-value="id"
                              label="Property Type"
                              chips
                              small-chips
                              deletable-chips
                              :append-icon="'mdi-refresh'"
                              @click:append="getPropertyTypes(false)"
                          >
                            <template v-slot:append-outer>
                              <a target="_blank" href="/setup/property-types?addModule=true">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                              </a>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-select
                                  clearable
                                  outlined
                                  dense
                                  :items="unitsFilter"
                                  v-model="builtUpAreaUnit"
                                  item-text="title"
                                  item-value="value"
                                  @change="clearChargesValues"
                                  label="Unit"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="8"
                                   v-if="builtUpAreaUnit !== 'Dimensions'"
                            >
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.built_up_area"
                                            :counter="10"
                                            maxLength="10"
                                            @keyup="clearChargesValues"
                                            @keypress="isNumber($event)"
                                            label="Built Up Area"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="builtUpAreaUnit && builtUpAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.built_up_area_width"
                                            :counter="10"
                                            maxLength="10"
                                            @keypress="isNumber($event)"
                                            label="Built Up Area Width"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="builtUpAreaUnit && builtUpAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.built_up_area_length"
                                            :counter="10"
                                            maxLength="10"
                                            @keypress="isNumber($event)"
                                            label="Built Up Area Length"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-select
                                  clearable
                                  outlined
                                  dense
                                  :items="unitsFilter"
                                  v-model="plotAreaUnit"
                                  item-text="title"
                                  item-value="value"
                                  @change="clearChargesValues"
                                  label="Unit"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="8" v-if="plotAreaUnit !== 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.plot_area"
                                            :counter="10"
                                            maxLength="10"
                                            @keyup="clearChargesValues"
                                            @keypress="isNumber($event)"
                                            label="Plot Area"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="plotAreaUnit && plotAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.plot_area_width"
                                            :counter="10"
                                            maxLength="10"
                                            @keypress="isNumber($event)"
                                            label="Plot Area Width"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="plotAreaUnit && plotAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.plot_area_length"
                                            :counter="10"
                                            maxLength="10"
                                            @keypress="isNumber($event)"
                                            label="Plot Area Length"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-select
                                  clearable
                                  outlined
                                  dense
                                  :items="unitsFilter"
                                  v-model="balconyAreaUnit"
                                  item-text="title"
                                  item-value="value"
                                  label="Unit"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="8"
                                   v-if="balconyAreaUnit !== 'Dimensions'"
                            >
                              <v-text-field clearable
                                            outlined
                                            dense v-model="editedItem.balcony_area"
                                            :counter="10"
                                            maxLength="10"
                                            @keypress="isNumber($event)"
                                            label="Balcony Area"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="balconyAreaUnit && balconyAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.balcony_area_width"
                                            :counter="10"
                                            maxLength="10"
                                            @keypress="isNumber($event)"
                                            label="Balcony Area Area Width"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="balconyAreaUnit && balconyAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.balcony_area_length"
                                            :counter="10"
                                            @keypress="isNumber($event)"
                                            label="Balcony Area Area Length"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-select
                                  clearable
                                  outlined
                                  dense
                                  :items="unitsFilter"
                                  v-model="terraceAreaUnit"
                                  item-text="title"
                                  item-value="value"
                                  label="Unit"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="8" v-if="terraceAreaUnit !== 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.terrace_area"
                                            :counter="10"
                                            @keypress="isNumber($event)"
                                            label="Terrace Area"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="terraceAreaUnit && terraceAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.terrace_area_width"
                                            :counter="10"
                                            @keypress="isNumber($event)"
                                            label="Terrace Area Width"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="terraceAreaUnit && terraceAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.terrace_area_length"
                                            :counter="10"
                                            @keypress="isNumber($event)"
                                            label="Terrace Area Length"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-select
                                  clearable
                                  outlined
                                  dense
                                  :items="unitsFilter"
                                  v-model="commonAreaUnit"
                                  item-text="title"
                                  item-value="value"
                                  label="Unit"
                              ></v-select>
                            </v-col>
                            <v-col cols="12" sm="8" v-if="commonAreaUnit !== 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.common_area"
                                            :counter="10"
                                            @keypress="isNumber($event)"
                                            label="Common Area"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="commonAreaUnit && commonAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.common_area_width"
                                            :counter="10"
                                            @keypress="isNumber($event)"
                                            label="Common Area Width"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4"
                                   v-if="commonAreaUnit && commonAreaUnit === 'Dimensions'">
                              <v-text-field clearable
                                            outlined
                                            dense
                                            v-model="editedItem.common_area_length"
                                            :counter="10"
                                            @keypress="isNumber($event)"
                                            label="Common Area Length"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.unit_no"
                                        maxLength="30"
                                        :counter="30"
                                        label="Unit Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.floor"
                                        maxLength="30"
                                        :counter="30"
                                        label="Floor"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.street_name_no"
                                        maxLength="100"
                                        :counter="100"
                                        label="Street Name/Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.year_built"
                                        maxLength="4"
                                        :counter="4"
                                        @keypress="isNumber($event)"
                                        label="Year Built"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.parking_no"
                                        maxLength="30"
                                        :counter="30"
                                        label="Parking Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.no_of_parking"
                                        maxLength="30"
                                        :counter="30"
                                        label="Number Of Parking"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.land_no"
                                        maxLength="50"
                                        :counter="50"
                                        label="Land Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.official_residence_no"
                                        maxLength="50"
                                        :counter="50"
                                        label="Official Residence Number/Makani Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.electricity_meter_no"
                                        maxLength="50"
                                        :counter="50"
                                        label="Electricity Meter Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.gas_meter_no"
                                        maxLength="50"
                                        :counter="50"
                                        label="Gas Meter Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense v-model="editedItem.municipality_number"
                                        maxLength="50"
                                        :counter="50"
                                        label="Municipality Number"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-btn
                              outlined
                              dense
                              color="#00004d"
                              dark
                              height="40"
                              @click="$store.state.selections.lrDialog = true"
                              style="width:100%"
                          >
                                  <span v-if="$store.state.selections.selectedLRIds.length > 0"
                                        v-html="$store.state.selections.selectedLRIds.length + ' Location Remark(s) Selected'"
                                  ></span>
                            <span v-else>Select Location Remarks</span>
                          </v-btn>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                              v-model="editedItem.owner_ids"
                              :items="ownersFilter"
                              item-text="full_name"
                              item-value="id"
                              clearable
                              outlined
                              dense
                              deletable-chips
                              chips
                              :loading="isOwnersLoaded"
                              small-chips
                              multiple
                              label="Owner(s) (min. 3 characters required)"
                              @keyup="ownersFilterKeyUp"
                          >
                            <template v-slot:append-outer>
                              <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                              </a>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-autocomplete
                              v-model="editedItem.cooling_provider_ids"
                              :items="coolingProvidersFilter"
                              item-text="full_name"
                              item-value="id"
                              clearable
                              outlined
                              dense
                              deletable-chips
                              chips
                              :loading="isCoolingProvidersLoaded"
                              small-chips
                              multiple
                              label="Monthly Cooling Provider(s) (min. 3 characters required)"
                              @keyup="coolingProvidersFilterKeyUp"
                          >
                            <template v-slot:append-outer>
                              <a target="_blank" href="/contacts-management/contacts?addModule=true">
                                <v-icon>mdi-plus-circle-outline</v-icon>
                              </a>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense
                                        :counter="20"
                                        @keypress="isNumber($event)"
                                        @keyup="yscKeyUp"
                                        v-model="yscValue"
                                        label="Yearly Service Charges"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select
                              clearable
                              outlined
                              dense
                              v-model="yscMultiplyValue"
                              :items="chargesMultiplies"
                              item-text="title"
                              item-value="value"
                              label="Built Up Area Sq Ft/Plot Size Sq Ft"
                              @change="yscMultiply"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense
                                        v-model="that.$store.state.developmentunitdetails.yearlyServiceCharges"
                                        :counter="20"
                                        @keypress="isNumber($event)"
                                        label="Total"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense
                                        :counter="20"
                                        @keypress="isNumber($event)"
                                        @keyup="mccKeyUp"
                                        v-model="mccValue"
                                        label="Monthly Cooling Charges"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-select
                              clearable
                              outlined
                              dense
                              v-model="mccMultiplyValue"
                              :items="chargesMultiplies"
                              item-text="title"
                              item-value="value"
                              label="Built Up Area Sq Ft/Plot Size Sq Ft"
                              @change="mccMultiply"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field clearable
                                        outlined
                                        dense
                                        v-model="that.$store.state.developmentunitdetails.monthlyCoolingCharges"
                                        :counter="20"
                                        @keypress="isNumber($event)"
                                        label="Total"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog">Cancel</v-btn>
                    <v-btn :disabled="!validationRules.valid" color="blue darken-1" text @click="saveItem(editedItem)">
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-divider></v-divider>
              <v-col cols="12">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
                      <div class="text-center font-weight-bold">
                        Search/Filters
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form v-on:submit.prevent="hitAdvanceSearch(false)">
                        <v-row>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.contactSearch"
                                :items="contactsFilter"
                                item-text="full_name"
                                item-value="id"
                                outlined
                                dense
                                deletable-chips
                                chips
                                :loading="isContactsLoaded"
                                hide-details
                                small-chips
                                label="Owner (min. 3 characters required)"
                                @keyup="contactsFilterKeyUp"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <h6 v-html="data.item.full_name"></h6>
                                      <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                      <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                      <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                outlined
                                dense
                                :items="propertyTypesFilter"
                                v-model="searchFormData.propertyTypeSearch"
                                item-text="full_title"
                                item-value="id"
                                label="Property Type"
                                chips
                                hide-details
                                small-chips
                                deletable-chips
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-text-field
                                outlined
                                dense
                                v-model="searchFormData.unitNumberSearch"
                                label="Unit Number"
                                hide-details
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-text-field :counter="10"
                                          maxLength="10"
                                          outlined
                                          hide-details
                                          dense maxlength="10" v-model="searchFormData.propertyTitleSearch"
                                          label="Property Title"></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="3">
                            <v-text-field :counter="10"
                                          maxLength="10"
                                          outlined
                                          hide-details
                                          dense maxlength="10" v-model="searchFormData.minBuaSearch"
                                          label="Min Built Up Area"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-text-field :counter="10"
                                          maxLength="10"
                                          outlined
                                          hide-details
                                          dense maxlength="10" v-model="searchFormData.maxBuaSearch"
                                          label="Max Built Up Area"></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="3">
                            <v-text-field :counter="10"
                                          maxLength="10"
                                          outlined
                                          hide-details
                                          dense maxlength="10" v-model="searchFormData.minPaSearch"
                                          label="Min Plot Area"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-text-field :counter="10"
                                          maxLength="10"
                                          outlined
                                          hide-details
                                          dense maxlength="10" v-model="searchFormData.maxPaSearch"
                                          label="Max Plot Area"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" type="submit" style="width: 100%"
                                    class="btn btn-success theme-btn ml-auto mr-2">
                              Search
                              <v-icon class="ml-2" small>fas fa-search</v-icon>
                            </button>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" style="width: 100%" @click="hitAdvanceSearch(true)"
                                    class="btn btn-primary ml-auto">
                              Reset
                              <v-icon class="ml-2" small>fas fa-times</v-icon>
                            </button>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12">
                <v-card-title>
                  Development Details ({{ addTSBVZ(totalRecords) }})
                </v-card-title>
                <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
                >
                </v-skeleton-loader>
                <v-data-table
                    :headers="headers"
                    :items="items"
                    dense
                    show-expand
                    fixed-header
                    height="500"
                    :options.sync="pagination"
                    :server-items-length="totalRecords"
                    :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                    :loading="loading"
                    v-show="!isLoaded"
                    mobile-breakpoint="100"
                    class="elevation-0">
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-row>
                        <v-col cols="12">
                          <v-card
                              class="mx-auto"
                              outlined
                              rounded
                          >
                            <v-card-text class="pt-0 pb-0">
                              <v-row>
                                <v-col cols="12" sm="3">
                                  <div>
                                    <h6>Owners</h6>
                                    <p class="custom-item" v-for="(owner, key) in item.owners" :key="key">
                                      <a :href="'/contacts-management/contacts?contact=' + owner.id"
                                         target="_blank" v-html="owner.full_name"></a><br />
                                    </p>
                                  </div>
                                  <div>
                                    <h6>Location Remarks</h6>
                                    <p v-html="item.location_remarks_titles ? item.location_remarks_titles : '-'"></p>
                                  </div>
                                </v-col>

                                <v-col cols="12" sm="3">
                                  <div>
                                    <h6>Balcony Area</h6>
                                    <p>
                                      <span v-html="item.balcony_area ? item.balcony_area + ' ' : '-'"></span>
                                      <span v-if="item.balcony_area" v-html="item.balcony_area_unit"></span></p>
                                  </div>

                                  <div>
                                    <h6>Terrace Area</h6>
                                    <p>
                                      <span v-html="item.terrace_area ? item.terrace_area + ' ' : '-'"></span>
                                      <span v-if="item.terrace_area" v-html="item.terrace_area_unit"></span></p>
                                  </div>

                                  <div>
                                    <h6>Common Area</h6>
                                    <p>
                                      <span v-html="item.common_area ? item.common_area + ' ' : '-'"></span>
                                      <span v-if="item.common_area" v-html="item.common_area_unit"></span></p>
                                  </div>

                                  <div>
                                    <h6>Street Name/Number</h6>
                                    <p v-html="item.street_name_no ? item.street_name_no : '-'"></p>
                                  </div>
                                </v-col>

                                <v-col cols="12" sm="3">
                                  <div>
                                    <h6>Year Built</h6>
                                    <p v-html="item.year_built ? item.year_built : '-'"></p>
                                  </div>
                                  <div>
                                    <h6>Parking Number</h6>
                                    <p v-html="item.parking_no ? item.parking_no : '-'"></p>
                                  </div>
                                  <div>
                                    <h6>Number of Parking</h6>
                                    <p v-html="item.no_of_parking ? item.no_of_parking : '-'"></p>
                                  </div>
                                  <div>
                                    <h6>Land Number</h6>
                                    <p v-html="item.land_no ? item.land_no : '-'"></p>
                                  </div>
                                </v-col>

                                <v-col cols="12" sm="3">
                                  <div>
                                    <h6>Official Residence Number/Makani Number</h6>
                                    <p v-html="item.official_residence_no ? item.official_residence_no : '-'"></p>
                                  </div>
                                  <div>
                                    <h6>Electricity Meter Number</h6>
                                    <p v-html="item.electricity_meter_no ? item.electricity_meter_no : '-'"></p>
                                  </div>
                                  <div>
                                    <h6>Gas Meter Number</h6>
                                    <p v-html="item.gas_meter_no ? item.gas_meter_no : '-'"></p>
                                  </div>
                                  <div>
                                    <h6>Land Number</h6>
                                    <p v-html="item.land_no ? item.land_no : '-'"></p>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </td>
                  </template>
                  <template v-slot:item.built_up_area_calc="{ item }">
                    <div class="text-truncate">
                      <span v-html="item.built_up_area ? item.built_up_area + ' ' : '-'"></span>
                      <span v-if="item.built_up_area" v-html="item.built_up_area_unit"></span>
                    </div>
                  </template>
                  <template v-slot:item.plot_area="{ item }">
                    <div class="text-truncate">
                      <span v-html="item.plot_area ? item.plot_area + ' ' : '-'"></span>
                      <span v-if="item.plot_area" v-html="item.plot_area_unit"></span>
                    </div>
                  </template>
                  <template v-slot:item.created_at="{ item }">
                    <div class="text-truncate">
                      {{ formatDateAndTime(item.created_at) }} <br> by
                      {{ item.created_user_name }}
                    </div>
                  </template>
                  <template v-slot:item.updated_at="{ item }">
                    <div class="text-truncate">
                      {{ formatDateAndTime(item.updated_at) }} <br> by
                      {{ item.updated_user_name }}
                    </div>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <div class="text-truncate">
                      <v-btn color="#00004d" small @click="showEditDialog(item)" dark class="mr-2">
                        <v-icon dark x-small>fas fa-edit</v-icon>
                      </v-btn>
                      <!--                    <v-btn color="red" dark @click="deleteItem(item)" class="mr-2">-->
                      <!--                      Delete-->
                      <!--                      <v-icon dark right small>fas fa-trash</v-icon>-->
                      <!--                    </v-btn>-->
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {
  DEVELOPMENTUNITDETAILS_LIST,
  CREATE_DEVELOPMENTUNITDETAILS,
  UPDATE_DEVELOPMENTUNITDETAILS,
  CLEAR_DEVELOPMENTUNITDETAILS_ERRORS,
  DELETE_DEVELOPMENTUNITDETAILS, GET_DEVELOPMENTUNITDETAILS_DETAILS,
} from "@/core/services/store/developmentunitdetails.module";
import LocationRemarksSelection from "@/view/pages/realstate/selections/LocationRemarksSelection";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {DEVELOPMENTTYPES_SIMPLE_LIST} from "@/core/services/store/developmenttypes.module";
import {CONTACTS_SIMPLE_LIST} from "@/core/services/store/contacts.module";
import {LOCATIONREMARKS_SIMPLE_LIST} from "@/core/services/store/locationremarks.module";

export default {
  name: 'development-unit-details',
  props: ['development'],
  components: {LocationRemarksSelection},
  data() {
    return {
      that: this,
      headers: [
        {text: "Plot Number", value: "unit_no", name: "unit_no", filterable: false, sortable: true},
        {text: 'Property Number', value: 'p_number', name: 'p_number', filterable: false, sortable: false},
        {text: 'Property Title', value: 'title', name: 'title', filterable: false, sortable: false},
        {
          text: 'Property Type',
          value: 'property_type.title',
          name: 'property_type.title',
          filterable: false,
          sortable: false
        },
        {text: "Built Up Area SqMt", value: "built_up_area_calc.square_meter", name: "built_up_area_sqmt", filterable: false, sortable: false},
        {text: "Built Up Area SqFt", value: "built_up_area_calc.square_feet", name: "built_up_area_sqft", filterable: false, sortable: false},
        {text: "Plot Area SqMt", value: "plot_area_calc.square_meter", name: "plot_area_sqmt", filterable: false, sortable: false},
        {text: "Plot Area SqFt", value: "plot_area_calc.square_feet", name: "plot_area_sqft", filterable: false, sortable: false},
        {text: "Floor", value: "floor", name: "floor", filterable: false, sortable: true},
        {
          text: "Municipality Number",
          value: "municipality_number",
          name: "municipality_number",
          filterable: false,
          sortable: false
        },
        {
          text: "Yearly Service Charges",
          value: "yearly_service_charges",
          name: "yearly_service_charges",
          filterable: false,
          sortable: false
        },
        {
          text: "Monthly Cooling Charges",
          value: "monthly_cooling_charges",
          name: "monthly_cooling_charges",
          filterable: false,
          sortable: false
        },
        {text: "Created", align: ' d-none', value: "created_at", name: "created_at", filterable: false, sortable: false},
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      columns: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 1) || "Must be greater than 1 characters",
          v => (v && v.length <= 100) || "Must be less than 100 characters"
        ]
      },
      propertyTypesFilter: [],
      ownersFilter: [],
      isOwnersLoaded: false,
      coolingProvidersFilter: [],
      isCoolingProvidersLoaded: false,
      locationRemarksFilter: [],
      locationRemarksIdsFilter: [],
      items: [],
      dialog: false,
      editedItem: {},
      yscValue: '',
      yscMultiplyValue: '',
      mccValue: '',
      mccMultiplyValue: '',
      chargesMultiplies: [
        {value: "0", title: 'Built up area sq ft'},
        {value: "1", title: 'Plot size sq ft'},
      ],
      builtUpAreaUnit: '',
      plotAreaUnit: '',
      balconyAreaUnit: '',
      terraceAreaUnit: '',
      commonAreaUnit: '',
      contactsFilter: [],
      isContactsLoaded: false,
      searchFormData: {}
    }
  },
  watch: {
    params: {
      handler() {
        this.loadData().then(response => {
          this.items = response.items;
          this.totalRecords = response.total;
        });
      },
      deep: true
    },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_DEVELOPMENTUNITDETAILS_ERRORS);
      if (!val) this.editedItem = {};
      if (!val) this.$store.state.developmentunitdetails.yearlyServiceCharges = '';
      if (!val) this.$store.state.developmentunitdetails.monthlyCoolingCharges = '';
      if (!val) this.$store.state.selections.selectedLRIds = []
      // if (val && !this.editedItem.id){
      //   this.editedItem.plot_area_unit = 'Square Feet';
      // }
      if (val) {
        this.builtUpAreaUnit = this.builtUpAreaUnit ? this.builtUpAreaUnit : this.$store.state.auth.user.measurement_unit
        this.plotAreaUnit = this.plotAreaUnit ? this.plotAreaUnit : this.$store.state.auth.user.measurement_unit
        this.balconyAreaUnit = this.balconyAreaUnit ? this.balconyAreaUnit : this.$store.state.auth.user.measurement_unit
        this.terraceAreaUnit = this.terraceAreaUnit ? this.terraceAreaUnit : this.$store.state.auth.user.measurement_unit
        this.commonAreaUnit = this.commonAreaUnit ? this.commonAreaUnit : this.$store.state.auth.user.measurement_unit
      } else {
        this.builtUpAreaUnit = ''
        this.plotAreaUnit = ''
        this.balconyAreaUnit = ''
        this.terraceAreaUnit = ''
        this.commonAreaUnit = ''
      }
    },
    development() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.getPropertyTypes();
  },
  methods: {
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        query += '&' + searchFormDataQuery;
        this.getHeaderIndex(params.sortBy[0]);
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 0 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "asc" : "desc");
        this.$store.dispatch(DEVELOPMENTUNITDETAILS_LIST, {
          development_id: this.development.id,
          q: query,
          columns: this.columns
        }).then((res) => {
          this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData() {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        this.getPropertyTypes();
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_DEVELOPMENTUNITDETAILS_DETAILS, item.id).then((data) => {
        this.editedItem = data.records || {};
        this.ownersFilter = data.records.owners;
        this.builtUpAreaUnit = data.records.built_up_area_unit
        this.plotAreaUnit = data.records.plot_area_unit
        this.balconyAreaUnit = data.records.balcony_area_unit
        this.terraceAreaUnit = data.records.terrace_area_unit
        this.commonAreaUnit = data.records.common_area_unit
        this.$store.state.developmentunitdetails.yearlyServiceCharges = data.records.yearly_service_charges
        this.$store.state.developmentunitdetails.monthlyCoolingCharges = data.records.monthly_cooling_charges
        this.coolingProvidersFilter = data.records.cooling_providers;
        this.$store.state.selections.selectedLRIds = this.editedItem.location_remark_ids
        this.dialog = !this.dialog;
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_DEVELOPMENTUNITDETAILS;
        let id = item.id;
        let data = {
          data: item
        };
        this.$set(item, "development_id", this.development.id);
        this.$set(item, "yearly_service_charges", this.$store.state.developmentunitdetails.yearlyServiceCharges ? this.$store.state.developmentunitdetails.yearlyServiceCharges.toFixed(3) : '');
        this.$set(item, "monthly_cooling_charges", this.$store.state.developmentunitdetails.monthlyCoolingCharges ? this.$store.state.developmentunitdetails.monthlyCoolingCharges.toFixed(3) : '');
        this.$set(item, "built_up_area_unit", this.builtUpAreaUnit);
        this.$set(item, "plot_area_unit", this.plotAreaUnit);
        this.$set(item, "balcony_area_unit", this.balconyAreaUnit);
        this.$set(item, "terrace_area_unit", this.terraceAreaUnit);
        this.$set(item, "common_area_unit", this.commonAreaUnit);
        this.$set(item, "location_remark_ids", this.$store.state.selections.selectedLRIds);
        if (id) {
          method = UPDATE_DEVELOPMENTUNITDETAILS;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_DEVELOPMENTUNITDETAILS_ERRORS);
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          this.editedItem = {}
          this.dialog = !this.dialog
        })
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?", msg: "You want to delete '" + item.title + "'?", clr: 'red', callback: function () {
          let id = item.id;
          this.$store.dispatch(DELETE_DEVELOPMENTUNITDETAILS, {
            'id': id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    getPropertyTypes(loadNext = true) {
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {type: 'Properties', is_property: 1}).then(data => {
        this.propertyTypesFilter = data.records.developmentTypesList;
        if (loadNext) this.getLocationRemarks();
      });
    },
    getLocationRemarks() {
      this.$store.dispatch(LOCATIONREMARKS_SIMPLE_LIST, {type: 'Developments'}).then(data => {
        this.locationRemarksFilter = data.records.locationRemarksList;
        let that = this;
        this.locationRemarksFilter.forEach(function (v) {
          if (v.children.length > 0) {
            v.children.forEach(function (vv) {
              that.locationRemarksIdsFilter.push(vv.id)
            })
          }
        })
      });
    },
    ownersFilterKeyUp(e) {
      if (e.target.value.length >= 3) {
        this.isOwnersLoaded = true;
        this.$store
            .dispatch(CONTACTS_SIMPLE_LIST, {
              role: 'Real Estate Owners',
              q: e.target.value
            })
            .then(data => {
              this.ownersFilter = data.records.contactsList;
              this.isOwnersLoaded = false;
            });
      }
    },
    contactsFilterKeyUp(e) {
      if (e.target.value.length >= 3) {
        this.isContactsLoaded = true;
        this.$store
            .dispatch(CONTACTS_SIMPLE_LIST, {
              role: 'All',
              q: e.target.value
            })
            .then(data => {
              this.contactsFilter = data.records.contactsList;
              this.isContactsLoaded = false;
            });
      }
    },
    coolingProvidersFilterKeyUp(e) {
      if (e.target.value.length >= 3) {
        this.isCoolingProvidersLoaded = true;
        this.$store
            .dispatch(CONTACTS_SIMPLE_LIST, {
              role: 'Cooling Providers',
              q: e.target.value
            })
            .then(data => {
              this.coolingProvidersFilter = data.records.contactsList;
              this.isCoolingProvidersLoaded = false;
            });
      }
    },
    yscKeyUp(evt) {
      if (!evt.target.value) {
        this.$store.state.developmentunitdetails.yearlyServiceCharges = ''
      }
      if (
          evt.target.value &&
          this.editedItem.built_up_area &&
          this.editedItem.built_up_area_unit &&
          this.yscMultiplyValue &&
          this.yscMultiplyValue === "0"
      ) {
        this.$store.state.developmentunitdetails.yearlyServiceCharges = this.unitCalculator(this.editedItem.built_up_area_unit, evt.target.value, this.editedItem.built_up_area)
      }

      if (
          evt.target.value &&
          this.editedItem.plot_area &&
          this.editedItem.plot_area_unit &&
          this.yscMultiplyValue &&
          this.yscMultiplyValue === "1"
      ) {
        this.$store.state.developmentunitdetails.yearlyServiceCharges = this.unitCalculator(this.editedItem.plot_area_unit, evt.target.value, this.editedItem.plot_area)
      }
    },
    yscMultiply(v) {
      if (!v) {
        this.$store.state.developmentunitdetails.yearlyServiceCharges = ''
      }
      if (v === '0') {
        if (
            this.yscValue &&
            this.editedItem.built_up_area &&
            this.editedItem.built_up_area_unit
        ) {
          this.$store.state.developmentunitdetails.yearlyServiceCharges = this.unitCalculator(this.editedItem.built_up_area_unit, this.yscValue, this.editedItem.built_up_area);
        }
      }
      if (v === '1') {
        if (
            this.yscValue &&
            this.editedItem.plot_area &&
            this.editedItem.plot_area_unit
        ) {
          this.$store.state.developmentunitdetails.yearlyServiceCharges = this.unitCalculator(this.editedItem.plot_area_unit, this.yscValue, this.editedItem.plot_area);
        }
      }
    },
    mccKeyUp(evt) {
      if (!evt.target.value) {
        this.$store.state.developmentunitdetails.monthlyCoolingCharges = ''
      }
      if (
          evt.target.value &&
          this.editedItem.built_up_area &&
          this.editedItem.built_up_area_unit &&
          this.mccMultiplyValue &&
          this.mccMultiplyValue === "0"
      ) {
        this.$store.state.developmentunitdetails.monthlyCoolingCharges = this.unitCalculator(this.editedItem.built_up_area_unit, evt.target.value, this.editedItem.built_up_area)
      }

      if (
          evt.target.value &&
          this.editedItem.plot_area &&
          this.editedItem.plot_area_unit &&
          this.mccMultiplyValue &&
          this.mccMultiplyValue === "1"
      ) {
        this.$store.state.developmentunitdetails.monthlyCoolingCharges = this.unitCalculator(this.editedItem.plot_area_unit, evt.target.value, this.editedItem.plot_area)
      }
    },
    mccMultiply(v) {
      if (!v) {
        this.$store.state.developmentunitdetails.monthlyCoolingCharges = ''
      }
      if (v === '0') {
        if (
            this.mccValue &&
            this.editedItem.built_up_area &&
            this.editedItem.built_up_area_unit
        ) {
          this.$store.state.developmentunitdetails.monthlyCoolingCharges = this.unitCalculator(this.editedItem.built_up_area_unit, this.mccValue, this.editedItem.built_up_area);
        }
      }
      if (v === '1') {
        if (
            this.mccValue &&
            this.editedItem.plot_area &&
            this.editedItem.plot_area_unit
        ) {
          this.$store.state.developmentunitdetails.monthlyCoolingCharges = this.unitCalculator(this.editedItem.plot_area_unit, this.mccValue, this.editedItem.plot_area);
        }
      }
    },
    clearChargesValues() {
      this.yscValue = '';
      this.mccValue = '';
      this.$store.state.developmentunitdetails.monthlyCoolingCharges = ''
      this.$store.state.developmentunitdetails.yearlyServiceCharges = ''
    },
    hitAdvanceSearch(reset = false) {
      if (reset) {
        this.searchFormData = {};
      }
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.developmentunitdetails.errors
    })
  }
};
</script>
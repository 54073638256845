<template>
  <div>
    <v-toolbar
        dark
        color="#00004d"
    >
      <v-btn
          icon
          dark
          @click="$emit('closeTreeDialog')"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0 hidden-sm-and-down">
        <span v-html="item.country ? item.country.title : ''"></span>
        <v-icon v-if="item.country" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

        <span v-html="item.city ? item.city.title : ''"></span>
        <v-icon v-if="item.city" dark x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

        <span
            v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development"
            dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development"
            dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development"
            dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon
            v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development"
            dark x-small class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span
            v-html="(item.parent_development && item.parent_development.parent_development) ? item.parent_development.parent_development.commercial_title : ''"></span>
        <v-icon v-if="item.parent_development && item.parent_development.parent_development" dark x-small
                class="ml-1 mr-1">fas fa-angle-double-right
        </v-icon>

        <span v-html="item.parent_development ? item.parent_development.commercial_title : ''"></span>
        <v-icon v-if="item.parent_development" dark x-small class="ml-1 mr-1">fas
          fa-angle-double-right
        </v-icon>
        <span class="font-weight-bold" v-html="item.commercial_title"></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
            dark
            text
            @click="$emit('editDevelopmentFD', item)"
        >
          Edit
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-tabs v-model="activeTabDetail" background-color="#002366" left dark>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>Sub Developments ({{ sdCount }}) & Projects ({{ pCount }})</v-tab>
      <v-tab>Details</v-tab>
      <v-tab>Description</v-tab>
      <v-tab>Location</v-tab>
      <v-tab>Social Media Links</v-tab>
      <v-tab>SEO</v-tab>
      <v-tab>Media</v-tab>
      <v-tab>Development Details</v-tab>
      <v-tab>Transactions</v-tab>
    </v-tabs>
    <v-tabs-items touchless v-model="activeTabDetail">
      <v-tab-item>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-treeview
                  v-if="item.hasOwnProperty('children') && item.children.length > 0"
                  class="custom-treeview"
                  transition
                  open-on-click
                  :items="item.hasOwnProperty('children') ? item.children : []"
                  dense
              >
                <template slot="label" slot-scope="{ item }">
                  <a href="javascript:void(0)">
                                    <span v-if="item.hasOwnProperty('commercial_title') && item.commercial_title"
                                          v-html="'<b>' + item.sr_no + '</b> - ' + item.commercial_title + ' (Sub Development ' + item.node + ')'"
                                    ></span></a>
                  <a :href="'/developments-management/projects?project=' + item.id" target="_blank">
                    <span v-if="item.hasOwnProperty('title') && item.title"
                          v-html="'<b>' + item.sr_no + '</b> - ' + item.title + ' (Project)'"
                    ></span>
                  </a>
                </template>
              </v-treeview>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="3">
              <h6>Commercial Title:</h6>
              <p class="hidden-sm-and-down" v-html="item.commercial_title"></p>
              <p class="hidden-md-and-up" v-if='item.type === "0"'>
                <span v-html="item.country ? item.country.title : ''"></span>
                <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>
                <span v-html="item.city ? item.city.title : ''"></span>
                <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>
                <span class="font-weight-bold" v-html="item.commercial_title"></span>
              </p>
              <p v-if='item.type === "1"' class="hidden-md-and-up">
                <span v-html="item.country ? item.country.title : ''"></span>
                <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                <span v-html="item.city ? item.city.title : ''"></span>
                <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas fa-angle-double-right</v-icon>

                <span
                    v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development && item.parent_development.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development) ? item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon
                    v-if="item.parent_development && item.parent_development.parent_development && item.parent_development.parent_development.parent_development"
                    x-small class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span
                    v-html="(item.parent_development && item.parent_development.parent_development) ? item.parent_development.parent_development.commercial_title : ''"></span>
                <v-icon v-if="item.parent_development && item.parent_development.parent_development"
                        x-small
                        class="ml-1 mr-1">fas fa-angle-double-right
                </v-icon>

                <span v-html="item.parent_development ? item.parent_development.commercial_title : ''"></span>
                <v-icon v-if="item.parent_development" x-small class="ml-1 mr-1">fas
                  fa-angle-double-right
                </v-icon>
                <span class="font-weight-bold" v-html="item.commercial_title"></span>
              </p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Official Title:</h6>
              <p v-html="item.official_title"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Type:</h6>
              <p v-html="item.development_type ? item.development_type.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Development Number:</h6>
              <p v-html="item.development_number ? item.development_number : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Completion Status:</h6>
              <p v-html="item.completion_status ? item.completion_status.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Life Cycle:</h6>
              <p v-html="item.life_cycle ? item.life_cycle.title : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Master Developers:</h6>
              <p v-html="item.contact_names ? item.contact_names : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Launch Date:</h6>
              <p v-html="item.launch_date ? formatMomentDate(item.launch_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Registration Date:</h6>
              <p v-html="item.registration_date ? formatMomentDate(item.registration_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Construction Started Date:</h6>
              <p v-html="item.construction_started_date ? formatMomentDate(item.construction_started_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Anticipated Completion Date:</h6>
              <p v-html="item.anticipated_completion_date ? formatMomentDate(item.anticipated_completion_date) : '-'"></p>
            </v-col>
            <v-col cols="12" sm="3">
              <h6>Handover Date:</h6>
              <p v-html="item.handover_date ? formatMomentDate(item.handover_date) : '-'"></p>
            </v-col>
            <v-col cols="12">
              <h6>Special Classifications:</h6>
              <p v-html="item.special_classification_titles ? item.special_classification_titles : '-'"></p>
            </v-col>
            <v-col cols="12">
              <h6>Amenities:</h6>
              <p v-html="item.amenity_titles ? item.amenity_titles : '-'"></p>
            </v-col>
            <v-col cols="12">
              <h6>Road Locations:</h6>
              <p v-html="item.road_location_titles ? item.road_location_titles : '-'"></p>
            </v-col>
          </v-row>
        </v-card-text>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p v-html="item.description ? item.description : '-'"></p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4">
                <h6>Location:</h6>
                <p v-html="item.location ? item.location : '-'"></p>
              </v-col>
              <v-col cols="12" sm="4">
                <h6>Latitude:</h6>
                <p v-html="item.latitude ? item.latitude : '-'"></p>
              </v-col>
              <v-col cols="12" sm="4">
                <h6>Longitude:</h6>
                <p v-html="item.longitude ? item.longitude : '-'"></p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <gmap-map
                    :zoom="20"
                    :center="center"
                    style="width:100%;  height: 400px;"
                    :clickable="true"
                >
                  <gmap-marker
                      :key="index"
                      v-for="(m, index) in locationMarkers"
                      :position="m.position"
                  ></gmap-marker>
                </gmap-map>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row v-if="item.hasOwnProperty('youtube_videos_links') && item.youtube_videos_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Youtube Videos:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.youtube_videos_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('youtube_playlist_links') && item.youtube_playlist_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Youtube Playlist:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.youtube_playlist_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('virtual_tour_links') && item.virtual_tour_links.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Virtual Tour:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.virtual_tour_links" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('website_urls') && item.website_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Website URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.website_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('instagram_urls') && item.instagram_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Instagram URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.instagram_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('facebook_urls') && item.facebook_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Facebook URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.facebook_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('twitter_urls') && item.twitter_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Twitter URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.twitter_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row v-if="item.hasOwnProperty('linkedin_urls') && item.linkedin_urls.length > 0">
              <v-col cols="12" class="pb-0">
                <h6>Linkedin URLS:</h6>
              </v-col>
              <v-col cols="12" sm="3" v-for="(link, index) in item.linkedin_urls" :key="index">
                <v-card class="mb-1">
                  <v-card-text>
                    <p class="mb-1" v-html="link.title"></p>
                    <a target="_blank"
                       :href="link.url"
                       v-html="link.url"></a>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h6>Meta Title:</h6>
                <p v-html="item.meta_title ? item.meta_title : '-'"></p>
              </v-col>
              <v-col cols="12">
                <h6>Meta Keywords:</h6>
                <p v-html="item.meta_keywords ? item.meta_keywords : '-'"></p>
              </v-col>
              <v-col cols="12">
                <h6>Focus Keywords:</h6>
                <p v-html="item.focus_keywords ? item.meta_keywords : '-'"></p>
              </v-col>
              <v-col cols="12">
                <h6>Meta Description:</h6>
                <p v-html="item.meta_description ? item.meta_description : '-'"></p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-tabs
            v-model="activeTabDetailInner"
            background-color="primary" left dark>
          <v-tab>Master Plan</v-tab>
          <v-tab>Gallery</v-tab>
          <v-tab>Brochure</v-tab>

          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('master_plan_files') && item.master_plan_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.master_plan_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500"
                        v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
          <v-tab-item>
            <Gallery v-if="item.hasOwnProperty('gallery_files') && item.gallery_files.length > 0"
                     :items="item.gallery_files"/>
          </v-tab-item>
          <v-tab-item>
            <v-carousel
                height="auto"
                :continuous="false" :cycle="false" hide-delimiters
                v-if="item.hasOwnProperty('brochure_files') && item.brochure_files.length > 0">
              <v-carousel-item
                  v-for="(item,i) in item.brochure_files"
                  :key="i"
              >
                <v-img
                    contain max-height="500"
                    v-if="checkFileExtension(item.extension)"
                    :src="item.file_public_url"
                ></v-img>

                <v-card height="500" v-else>
                  <iframe
                      onload="this.style.height = this.contentWindow.document.body.scrollHeight + 'px'"
                      style='height: 100%; width: 100%;' frameborder="0" scrolling="no"
                      :src='item.file_public_url' allowfullscreen></iframe>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-tab-item>
        </v-tabs>
      </v-tab-item>
      <v-tab-item>
        <ProjectUnitDetails
            pudType="development"
            contact=""
            listingId=""
            :project="item"
        ></ProjectUnitDetails>
      </v-tab-item>
      <v-tab-item>
        <v-tabs v-model="activeTabSecond" background-color="primary" centered dark>
          <v-tab>Sale</v-tab>
          <v-tab>Rent</v-tab>
        </v-tabs>
        <v-tabs-items touchless v-model="activeTabSecond">
          <v-tab-item>
            <DevelopmentFullTransactions :development="item" purpose="1"/>
          </v-tab-item>
          <v-tab-item>
            <DevelopmentFullTransactions :development="item" purpose="2"/>
          </v-tab-item>
        </v-tabs-items>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import DevelopmentUnitDetails from "@/view/pages/realstate/developments/inc/DevelopmentUnitDetails";
import DevelopmentFullTransactions from "@/view/pages/realstate/developments/inc/DevelopmentFullTransactions";
import Gallery from "@/view/pages/realstate/media/Gallery";
import ProjectUnitDetails from "@/view/pages/realstate/projects/inc/ProjectUnitDetails";

export default {
  name: 'development-details',
  components: {ProjectUnitDetails, Gallery, DevelopmentFullTransactions, DevelopmentUnitDetails},
  props: ['item'],
  data() {
    return {
      center: {},
      locationMarkers: [],
      sdCount: this.item.sdCount,
      pCount: this.item.pCount,
    }
  },
  watch: {
    item() {
      this.activeTabDetail = 0
      this.activeTabDetailInner = 0
      this.item.latitude = this.item.latitude ? parseFloat(this.item.latitude) : 0;
      this.item.longitude = this.item.longitude ? parseFloat(this.item.longitude) : 0;
      const marker = {
        lat: this.item.latitude ? parseFloat(this.item.latitude) : 0,
        lng: this.item.longitude ? parseFloat(this.item.longitude) : 0
      };
      this.locationMarkers.push({position: marker});
      this.center = marker;
      this.sdCount = this.item.sdCount
      this.pCount = this.item.pCount
    }
  },
  mounted() {
    this.item.latitude = this.item.latitude ? parseFloat(this.item.latitude) : 0;
    this.item.longitude = this.item.longitude ? parseFloat(this.item.longitude) : 0;
    const marker = {
      lat: this.item.latitude ? parseFloat(this.item.latitude) : 0,
      lng: this.item.longitude ? parseFloat(this.item.longitude) : 0
    };
    this.locationMarkers.push({position: marker});
    this.center = marker;
    if (this.$route.query.hasOwnProperty('t')) {
      this.activeTabDetail = 8
    }
  },
  methods: {},
  computed: {}
};
</script>
<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <template>
            <v-card>
              <SpecialClassificationsSelection
                  @getSpecialClassifications="getSpecialClassifications(false)"
                  :scFilter="specialClassificationsFilter"
                  :scIdsFilter="specialClassificationsIdsFilter"/>
              <AmenitiesSelection
                  @getAmenities="getAmenities(false)"
                  :amenitiesFilter="amenitiesFilter"
                  :amenitiesIdsFilter="amenitiesIdsFilter"/>
              <RoadLocationsSelection
                  @getRoadLocations="getRoadLocations"
                  :rlFilter="rlFilter"
                  :rlIdsFilter="rlIdsFilter"/>
              <v-dialog persistent v-model="msdDialog" max-width="700">
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="msdDialog = false; itemToMsd = ''; itemFromMsd = {}"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>Select {{ itemFromMsd.commercial_title }}'s Master Development</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!itemToMsd"
                          dark
                          text
                          @click="updateStatus(itemToMsd, itemFromMsd.id, 'msd')"
                      >
                        Make Sub Development
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-autocomplete
                              v-model="itemToMsd"
                              :items="developmentsFilter"
                              item-text="commercial_title"
                              item-value="id"
                              clearable
                              outlined
                              dense
                              deletable-chips
                              chips
                              :loading="isDevelopmentsLoaded"
                              hide-details
                              label="Select Parent Development (min. 3 characters required)"
                              @keyup="developmentsFilterKeyUpWT"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content
                                    v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                    <v-icon v-if="data.item.country" x-small
                                            class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                    <v-icon v-if="data.item.city" x-small
                                            class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="moveDialog" fullscreen>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="moveDialog = false; itemFromMove = {}; itemToMove = ''; projectIdsToMove = []"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                      <span>Move {{ itemFromMove.commercial_title }}'s Projects</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!itemToMove || projectIdsToMove.length === 0"
                          dark
                          text
                          @click="updateStatus(projectIdsToMove, itemToMove, 'move_projects')"
                      >
                        Move
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                              v-model="projectIdsToMove"
                              :items="projectsFilterToMove"
                              item-text="title"
                              item-value="id"
                              hide-details
                              clearable
                              outlined
                              dense
                              multiple
                              deletable-chips
                              chips
                              small-chips
                              label="Select Projects"
                          >
                            <template slot="prepend-item">
                              <v-list-item>
                                <v-btn color="#00004d"
                                       dark
                                       class="mr-3"
                                       @click="projectIdsToMove = projectIdsFilterToMove">
                                  Check All
                                </v-btn>
                                <v-btn color="primary"
                                       @click="projectIdsToMove = []"
                                >Uncheck All
                                </v-btn>
                              </v-list-item>
                              <v-divider></v-divider>
                            </template>
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content
                                    v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                    <v-icon v-if="data.item.country" x-small
                                            class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                    <v-icon v-if="data.item.city" x-small
                                            class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development && data.item.development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development) ? data.item.development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development && data.item.development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.development && data.item.development.parent_development) ? data.item.development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.development && data.item.development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="data.item.development ? data.item.development.commercial_title : ''"></span>
                                    <v-icon v-if="data.item.development" x-small
                                            class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="'<strong>' + data.item.title + '</strong>'"></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-autocomplete
                              v-model="itemToMove"
                              :items="developmentsFilter"
                              item-text="commercial_title"
                              item-value="id"
                              clearable
                              outlined
                              dense
                              deletable-chips
                              chips
                              :loading="isDevelopmentsLoaded"
                              hide-details
                              label="Move Projects To (min. 3 characters required)"
                              @keyup="developmentsFilterKeyUpWT"
                          >
                            <template v-slot:item="data">
                              <template v-if="typeof data.item !== 'object'">
                                <v-list-item-content
                                    v-text="data.item"></v-list-item-content>
                              </template>
                              <template v-else>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                    <v-icon v-if="data.item.country" x-small
                                            class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                    <v-icon v-if="data.item.city" x-small
                                            class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span
                                        v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                                    <v-icon
                                        v-if="data.item.parent_development"
                                        x-small class="ml-1 mr-1">fas
                                      fa-angle-double-right
                                    </v-icon>

                                    <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <v-dialog v-model="treeDialog" fullscreen>
                <v-card>
                  <DevelopmentDetails @editDevelopmentFD="editDevelopmentFD" :item="treeItem"
                                      @closeTreeDialog="treeDialog = false"/>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialog" fullscreen
                        hide-overlay
                        transition="dialog-bottom-transition">
                <template v-slot:activator="{ on }">
                  <div class="d-flex">
                    <v-btn
                        color="#00004d"
                        dark
                        class="ml-auto ma-3 mr-1"
                        @click="refreshData(true)"
                    >
                      <i style="color: #fff" class="flaticon2-refresh-1"></i>
                    </v-btn>
                    <v-btn v-if="hasPermission('master_developments.create')" color="#00004d" dark
                           class="ma-3 ml-0"
                           v-on="on">
                      Add
                      <v-icon small>mdi-plus-circle-outline</v-icon>
                    </v-btn>
                  </div>
                </template>
                <v-card>
                  <v-toolbar
                      dark
                      color="#00004d"
                  >
                    <v-btn
                        icon
                        dark
                        @click="closeDialog"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                                            <span class="hidden-sm-and-down"
                                                  v-if="editedItem.id">Edit {{ editedItem.commercial_title }}</span>
                      <span v-else>Create Master Development</span>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                      <v-btn
                          :disabled="!validationRules.valid"
                          dark
                          text
                          @click="saveItem(editedItem)"
                      >
                        Save
                      </v-btn>
                    </v-toolbar-items>
                  </v-toolbar>
                  <v-tabs v-model="activeTab" background-color="#002366" left dark>
                    <v-tabs-slider></v-tabs-slider>
                    <v-tab>Basic Details</v-tab>
                    <v-tab>Important Dates</v-tab>
                    <v-tab>Description</v-tab>
                    <v-tab>Location</v-tab>
                    <v-tab>Social Media Handler</v-tab>
                    <v-tab>SEO Handler</v-tab>
                    <v-tab>Media</v-tab>
                    <v-tab v-if="editedItem.id">Development Details</v-tab>
                  </v-tabs>
                  <v-tabs-items touchless v-model="activeTab">
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form
                              ref="form"
                              v-model="validationRules.valid"
                              lazy-validation
                          >
                            <v-row>
                              <v-col cols="12" sm="3">
                                <v-text-field clearable
                                              outlined
                                              dense
                                              v-model="editedItem.commercial_title"
                                              :counter="100"
                                              :rules="validationRules.titleRules"
                                              label="Commercial Title*"
                                              required></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field clearable
                                              outlined
                                              dense
                                              :rules="validationRules.titleRules"
                                              v-model="editedItem.official_title"
                                              :counter="100"
                                              label="Official Title*"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.mp_title"
                                              :counter="100"
                                              label="MP Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.pm_title"
                                              :counter="100"
                                              label="PM Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.bayut_title"
                                              :counter="200"
                                              label="Bayut Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.dubizzle_title"
                                              :counter="200"
                                              label="Dubizzle Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense v-model="editedItem.pf_title"
                                              :counter="200"
                                              label="PF Title"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-text-field clearable
                                              outlined
                                              dense
                                              v-model="editedItem.development_number"
                                              :counter="100"
                                              label="Development Number"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.contact_ids"
                                    :items="contactsFilter"
                                    item-text="full_name"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    :loading="isContactsLoaded"
                                    small-chips
                                    multiple
                                    label="Master Developer(s) (min. 3 characters required)"
                                    @keyup="contactsFilterKeyUp"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank"
                                       href="/contacts-management/contacts?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content
                                          v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title>
                                          <h6 v-html="data.item.full_name"></h6>
                                          <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                          <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                          <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.type_id"
                                    :items="developmentTypesFilter"
                                    item-text="full_title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    small-chips
                                    label="Development Type"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getDevelopmentTypes(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank"
                                       href="/setup/development-types?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.completion_status_id"
                                    :items="completionStatusesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    small-chips
                                    label="Completion Status"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getCompletionStatuses(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank"
                                       href="/setup/completion-status?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content
                                          v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-autocomplete
                                    v-model="editedItem.life_cycle_id"
                                    :items="lifeCyclesFilter"
                                    item-text="title"
                                    item-value="id"
                                    clearable
                                    outlined
                                    dense
                                    deletable-chips
                                    chips
                                    small-chips
                                    label="Life Cycle"
                                    :append-icon="'mdi-refresh'"
                                    @click:append="getLifeCycles(false)"
                                >
                                  <template v-slot:append-outer>
                                    <a target="_blank"
                                       href="/setup/life-cycles?addModule=true">
                                      <v-icon>mdi-plus-circle-outline</v-icon>
                                    </a>
                                  </template>
                                  <template v-slot:item="data">
                                    <template v-if="typeof data.item !== 'object'">
                                      <v-list-item-content
                                          v-text="data.item"></v-list-item-content>
                                    </template>
                                    <template v-else>
                                      <v-list-item-content>
                                        <v-list-item-title
                                            v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                      </v-list-item-content>
                                    </template>
                                  </template>
                                </v-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-btn
                                    outlined
                                    dense
                                    color="#00004d"
                                    dark
                                    height="40"
                                    @click="$store.state.selections.scDialog = true"
                                    style="width:100%"
                                >
                                  <span v-if="$store.state.selections.selectedSCIds.length > 0"
                                        v-html="$store.state.selections.selectedSCIds.length + ' Special Classification(s) Selected'"
                                  ></span>
                                  <span v-else>Select Special Classifications</span>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-btn
                                    outlined
                                    dense
                                    color="#00004d"
                                    dark
                                    height="40"
                                    @click="$store.state.selections.amenitiesDialog = true"
                                    style="width:100%"
                                >
                                  <span v-if="$store.state.selections.selectedAmenityIds.length > 0"
                                        v-html="$store.state.selections.selectedAmenityIds.length + ' Amenities/Feature(s) Selected'"
                                  ></span>
                                  <span v-else>Select Amenities/Features</span>
                                </v-btn>
                              </v-col>
                              <v-col cols="12" sm="4">
                                <v-btn
                                    outlined
                                    dense
                                    color="#00004d"
                                    dark
                                    height="40"
                                    @click="$store.state.selections.rlDialog = true"
                                    style="width:100%"
                                >
                                  <span v-if="$store.state.selections.selectedRLIds.length > 0"
                                        v-html="$store.state.selections.selectedRLIds.length + ' Road Location(s) Selected'"
                                  ></span>
                                  <span v-else>Select Road Locations</span>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date"
                                            v-model="editedItem.launch_date"
                                            label="Launch Date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date"
                                            v-model="editedItem.registration_date"
                                            label="Registration Date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date"
                                            v-model="editedItem.construction_started_date"
                                            label="Construction Started Date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date"
                                            v-model="editedItem.anticipated_completion_date"
                                            label="Anticipated Completion Date"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="4">
                              <v-text-field clearable
                                            outlined
                                            dense type="date"
                                            v-model="editedItem.handover_date"
                                            label="Handover Date"></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <!--                                <h5>Description</h5>-->
                                <!--                                <br>-->
                                <!--                                <quill-editor-->
                                <!--                                    style="height: 300px"-->
                                <!--                                    v-model="editedItem.description"-->
                                <!--                                />-->
                                <editor
                                    :api-key="tinyKey"
                                    ref="rc_editor"
                                    v-model="editedItem.description"
                                    :init="tinyEditorInit"
                                />
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <gmap-autocomplete
                                    class="gmap-input"
                                    :value="mapInput"
                                    :select-first-on-enter="true"
                                    @place_changed="initMarker"></gmap-autocomplete>
                              </v-col>
                              <v-col cols="12" sm="3">
                                <v-btn color="#00004d"
                                       dark
                                       class="ml-auto"
                                       style="width:100%"
                                       @click="addLocationMarker">Search
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                    clearable
                                    outlined
                                    dense
                                    v-model="editedItem.location"
                                    label="Location"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field readonly
                                              v-model="this.$store.state.developments.latitude"
                                              label="Latitude"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <v-text-field readonly
                                              v-model="this.$store.state.developments.longitude"
                                              label="Longitude"></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <gmap-map
                                    :zoom="20"
                                    :center="center"
                                    style="width:100%;  height: 400px;"
                                    :clickable="true"
                                    @click="setLatLong($event)"
                                >
                                  <gmap-marker
                                      :key="index"
                                      v-for="(m, index) in locationMarkers"
                                      :position="m.position"
                                  ></gmap-marker>
                                </gmap-map>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-row>
                            <ModuleLinks
                                moduleOf="development_links"
                                moduleFor="youtube_videos_links"
                                moduleTitle="Youtube Videos"
                                :moduleLinksOld="editedItem.youtube_videos_links ? editedItem.youtube_videos_links : editedItem.youtube_videos_links === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="development_links"
                                moduleFor="youtube_playlist_links"
                                moduleTitle="Youtube Playlist"
                                :moduleLinksOld="editedItem.youtube_playlist_links ? editedItem.youtube_playlist_links : editedItem.youtube_playlist_links === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="development_links"
                                moduleFor="virtual_tour_links"
                                moduleTitle="Virtual Tour"
                                :moduleLinksOld="editedItem.virtual_tour_links ? editedItem.virtual_tour_links : editedItem.virtual_tour_links === []"
                            ></ModuleLinks>
                            <hr>
                            <ModuleLinks
                                moduleOf="development_links"
                                moduleTitle="Website URLS"
                                :moduleLinksOld="editedItem.website_urls ? editedItem.website_urls : editedItem.website_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="development_links"
                                moduleFor="instagram_urls"
                                moduleTitle="Instagram URLS"
                                :moduleLinksOld="editedItem.instagram_urls ? editedItem.instagram_urls : editedItem.instagram_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="development_links"
                                moduleFor="facebook_urls"
                                moduleTitle="Facebook URLS"
                                :moduleLinksOld="editedItem.facebook_urls ? editedItem.facebook_urls : editedItem.facebook_urls === []"
                            ></ModuleLinks>
                            <hr>
                            <ModuleLinks
                                moduleOf="development_links"
                                moduleFor="twitter_urls"
                                moduleTitle="Twitter URLS"
                                :moduleLinksOld="editedItem.twitter_urls ? editedItem.twitter_urls : editedItem.twitter_urls === []"
                            ></ModuleLinks>

                            <ModuleLinks
                                moduleOf="development_links"
                                moduleFor="linkedin_urls"
                                moduleTitle="Linkedin URLS"
                                :moduleLinksOld="editedItem.linkedin_urls ? editedItem.linkedin_urls : editedItem.linkedin_urls === []"
                            ></ModuleLinks>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <v-form>
                            <v-row>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                    clearable
                                    outlined
                                    dense v-model="editedItem.meta_title"
                                    :counter="500"
                                    name="input-7-1"
                                    label="Meta Title"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                    clearable
                                    outlined
                                    dense v-model="editedItem.meta_keywords"
                                    :counter="500"
                                    name="input-7-1"
                                    label="Meta Keywords"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-text-field
                                    clearable
                                    outlined
                                    dense v-model="editedItem.focus_keywords"
                                    :counter="500"
                                    name="input-7-1"
                                    label="Focus Keywords"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12">
                                <v-textarea
                                    clearable
                                    outlined
                                    dense v-model="editedItem.meta_description"
                                    :counter="500"
                                    name="input-7-1"
                                    label="Meta Description"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item>
                      <v-tabs
                          background-color="primary" left dark>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab>Master Plan</v-tab>
                        <v-tab>Gallery</v-tab>
                        <v-tab>Brochure</v-tab>

                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="development_files"
                              moduleTitle="Master Plan"
                              acceptedFiles="image/jpeg, image/png, application/pdf"
                              :moduleMultiple="true"
                              moduleFor="master_plan"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="developments"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="development_files"
                              moduleKey="development_id"
                              moduleTitle="Master Plan"
                              moduleOfFolder="developments"
                          ></ModuleUploadedFiles>
                        </v-tab-item>
                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="development_files"
                              moduleTitle="Gallery"
                              moduleFor="master_development_gallery"
                              acceptedFiles="image/jpeg, image/png"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="developments"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="development_files"
                              moduleKey="development_id"
                              moduleTitle="Gallery"
                              moduleOfFolder="developments"
                          ></ModuleUploadedFiles>
                        </v-tab-item>
                        <v-tab-item>
                          <ModuleMedia
                              moduleType="other"
                              moduleOf="development_files"
                              moduleTitle="Brochure"
                              moduleFor="master_development_brochure"
                              acceptedFiles="application/pdf"
                              :moduleMultiple="true"
                              :deleteModule="true"
                              :moduleFilesOld="[]"
                              moduleOfFolder="developments"
                          ></ModuleMedia>
                          <ModuleUploadedFiles
                              v-if="showUploadedFiles"
                              :moduleItem="editedItem"
                              moduleOf="development_files"
                              moduleKey="development_id"
                              moduleTitle="Brochure"
                              moduleOfFolder="developments"
                          ></ModuleUploadedFiles>
                        </v-tab-item>
                      </v-tabs>
                    </v-tab-item>
                    <v-tab-item v-if="editedItem.id">
                      <ProjectUnitDetails
                          pudType="development"
                          contact=""
                          listingId=""
                          :project="editedItem"
                      ></ProjectUnitDetails>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDialog"
                    >Cancel
                    </v-btn
                    >
                    <v-btn
                        :disabled="!validationRules.valid"
                        color="blue darken-1"
                        text
                        @click="saveItem(editedItem)"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                  <v-col cols="12">
                    <div v-if="errors!=undefined && errors.length >= 0"
                         role="alert"
                         v-bind:class="{ show: errors.length }"
                         class="alert fade alert-danger"
                    >
                      <div class="alert-text" v-for="(error, i) in errors" :key="i">
                        {{ error }}
                      </div>
                    </div>
                  </v-col>
                </v-card>
              </v-dialog>
              <hr style="margin:0"/>
              <div class="col-md-12">
                <v-expansion-panels accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="custom-expansion-panel-header" color="#00004d">
                      <div class="text-center font-weight-bold">
                        Search/Filters
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form ref="searchForm" v-on:submit.prevent="hitAdvanceSearch(false)">
                        <v-row>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.ctSearch"
                                :items="developmentsFilter"
                                item-text="commercial_title"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                deletable-chips
                                chips
                                :loading="isDevelopmentsLoaded"
                                small-chips
                                hide-details
                                label="Commercial Title (min. 3 characters required)"
                                @keyup="developmentsFilterKeyUp"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content
                                      v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <span v-html="data.item.country ? data.item.country.title : ''"></span>
                                      <v-icon v-if="data.item.country" x-small
                                              class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span v-html="data.item.city ? data.item.city.title : ''"></span>
                                      <v-icon v-if="data.item.city" x-small
                                              class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development && data.item.parent_development.parent_development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development) ? data.item.parent_development.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development && data.item.parent_development.parent_development && data.item.parent_development.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development && data.item.parent_development.parent_development) ? data.item.parent_development.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development && data.item.parent_development.parent_development"
                                          x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span
                                          v-html="(data.item.parent_development) ? data.item.parent_development.commercial_title : ''"></span>
                                      <v-icon
                                          v-if="data.item.parent_development"
                                          x-small class="ml-1 mr-1">fas
                                        fa-angle-double-right
                                      </v-icon>

                                      <span v-html="'<strong>' + data.item.commercial_title + '</strong>'"></span>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-text-field
                                v-model="searchFormData.otSearch"
                                clearable
                                outlined
                                maxLength="300"
                                dense
                                hide-details
                                label="Official Title"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.developmentTypeSearch"
                                :items="developmentTypesFilter"
                                item-text="full_title"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                hide-details
                                deletable-chips
                                chips
                                small-chips
                                label="Development Type"
                            >
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.completionStatusSearch"
                                :items="completionStatusesFilter"
                                item-text="title"
                                item-value="id"
                                clearable
                                outlined
                                dense
                                deletable-chips
                                chips
                                small-chips
                                hide-details
                                label="Completion Status"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content
                                      v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title
                                        v-html="data.item.parent ? data.item.title + ' (' + data.item.parent.title + ')' : data.item.title"></v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-autocomplete
                                v-model="searchFormData.mdSearch"
                                :items="contactsFilter"
                                item-text="full_name"
                                item-value="id"
                                outlined
                                dense
                                deletable-chips
                                chips
                                :loading="isContactsLoaded"
                                hide-details
                                small-chips
                                label="Master Developer (min. 3 characters required)"
                                @keyup="contactsFilterKeyUp"
                            >
                              <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                  <v-list-item-content
                                      v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <h6 v-html="data.item.full_name"></h6>
                                      <p v-html="'Phone(s):- ' + data.item.contact_phone_titles"></p>
                                      <p v-html="'Email(s):- ' + data.item.contact_email_titles"></p>
                                      <p v-html="'Role(s):- ' + data.item.contact_role_titles"></p>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-select :items="[{'title': 'Yes', 'value': 1},{'title': 'No', 'value': 0}]"
                                      v-model="searchFormData.ftSearch"
                                      hide-details
                                      item-text="title"
                                      item-value="value"
                                      outlined
                                      dense
                                      label="Featured"
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" type="submit" style="width: 100%"
                                    class="btn btn-success theme-btn ml-auto mr-2">
                              Search
                              <v-icon class="ml-2" small>fas fa-search</v-icon>
                            </button>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <button :disabled="loading" style="width: 100%"
                                    @click="hitAdvanceSearch(true)"
                                    class="btn btn-primary ml-auto">
                              Reset
                              <v-icon class="ml-2" small>fas fa-times</v-icon>
                            </button>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <v-card-title>
                Master Developments ({{ addTSBVZ(totalRecords) }})
              </v-card-title>
              <v-row>
                <v-col cols="12">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
              <v-skeleton-loader :loading="loading" v-if="isLoaded" type="table"
              >
              </v-skeleton-loader>
              <v-toolbar v-if="mainDataTableSelection.length > 0" class="mrc-fixed-bar" height="50"
                         dark
                         color="#00004d">
                <v-toolbar-items>
                  <v-checkbox class="mt-3" v-if="items.length > 0"
                              :indeterminate="(items.length > mainDataTableSelection.length) && (mainDataTableSelection.length > 0)"
                              :value="items.length === mainDataTableSelection.length"
                              @change="bulkSelectChange($event, items)"></v-checkbox>
                  <v-btn
                      dark
                      text
                      target="_blank"
                      :href="'/leads?tol=developments&refs=' + mainDataTableSelection.map( (el) => el.id).toString()"
                  >
                    Add Inquiry
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text v-if="!isLoaded">
                <v-row v-if="items.length > 0">
                  <v-col cols="12" v-for="(item, index) in items" :key="index">
                    <v-card
                        class="mx-auto"
                        outlined
                        raised
                    >
                      <v-toolbar
                          height="40"
                          dark
                          color="#00004d"
                      >
                        <v-checkbox class="mt-5"
                                    :true-value="1"
                                    :false-value="0"
                                    :value="item"
                                    v-model="mainDataTableSelection"></v-checkbox>
                        <v-toolbar-title class="hidden-sm-and-down">
                          <span v-html="item.country ? item.country.title : ''"></span>
                          <v-icon v-if="item.country" dark x-small class="ml-1 mr-1">fas
                            fa-angle-double-right
                          </v-icon>
                          <span v-html="item.city ? item.city.title : ''"></span>
                          <v-icon v-if="item.city" dark x-small class="ml-1 mr-1">fas
                            fa-angle-double-right
                          </v-icon>
                          <span class="font-weight-bold"
                                v-html="item.commercial_title"></span>
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-items class="hidden-sm-and-down">
                          <v-btn
                              v-if="item.projects_count > 0 && $store.state.auth.user.type === 1"
                              dark
                              text
                              @click="showTreeView(item, 'projects')"
                          >
                            Move Projects
                          </v-btn>
                          <v-btn
                              v-if="$store.state.auth.user.type === 1"
                              dark
                              text
                              @click="updateStatus('0',item.id,'make_project')"
                          >
                            Make Project
                          </v-btn>
                          <v-btn
                              v-if="$store.state.auth.user.type === 1"
                              dark
                              text
                              @click="msdDialog = true; itemFromMsd = item"
                          >
                            Make Sub Development
                          </v-btn>
                          <v-btn
                              dark
                              text
                              @click="showTreeView(item)"
                          >
                            View Details
                          </v-btn>
                          <v-btn
                              v-if="hasPermission('master_developments.update')"
                              dark
                              text
                              @click="syncDevelopmentTransactions(item)"
                          >
                            Sync
                          </v-btn>
                          <v-btn
                              v-if="hasPermission('master_developments.update')"
                              dark
                              text
                              @click="showEditDialog(item)"
                          >
                            Edit
                          </v-btn>
                          <v-btn
                              v-if="hasPermission('master_developments.archive')"
                              dark
                              text
                              @click="deleteItem(item)"
                          >
                            Archive
                          </v-btn>
                        </v-toolbar-items>
                        <v-menu
                            bottom
                            :close-on-content-click="true"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="hidden-md-and-up"
                                height="25"
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                            >
                              Actions
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                                v-if="item.projects_count > 0 && $store.state.auth.user.type === 1">
                              <v-btn
                                  text
                                  @click="showTreeView(item, 'projects')"
                              >
                                Move Projects
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="$store.state.auth.user.type === 1">
                              <v-btn
                                  text
                                  @click="updateStatus('0',item.id,'make_project')"
                              >
                                Make Project
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="$store.state.auth.user.type === 1">
                              <v-btn
                                  text
                                  @click="msdDialog = true; itemFromMsd = item"
                              >
                                Make Sub Development
                              </v-btn>
                            </v-list-item>
                            <v-list-item>
                              <v-btn
                                  text
                                  @click="showTreeView(item)"
                              >
                                View Details
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="hasPermission('master_developments.update')">
                              <v-btn
                                  text
                                  @click="syncDevelopmentTransactions(item)"
                              >
                                Sync Transactions
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="hasPermission('master_developments.update')">
                              <v-btn
                                  text
                                  @click="showEditDialog(item)"
                              >
                                Edit
                              </v-btn>
                            </v-list-item>
                            <v-list-item
                                v-if="hasPermission('master_developments.archive')">
                              <v-btn
                                  text
                                  @click="deleteItem(item)"
                              >
                                Archive
                              </v-btn>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="3">
                            <h6>Commercial Title</h6>
                            <p class="hidden-sm-and-down"
                               v-html="item.commercial_title"></p>
                            <p class="hidden-md-and-up">
                              <span v-html="item.country ? item.country.title : ''"></span>
                              <v-icon v-if="item.country" x-small class="ml-1 mr-1">fas
                                fa-angle-double-right
                              </v-icon>
                              <span v-html="item.city ? item.city.title : ''"></span>
                              <v-icon v-if="item.city" x-small class="ml-1 mr-1">fas
                                fa-angle-double-right
                              </v-icon>
                              <span class="font-weight-bold"
                                    v-html="item.commercial_title"></span>
                            </p>

                            <h6>Official Title</h6>
                            <p v-html="item.official_title"></p>
                            <h6>Type</h6>
                            <p v-html="item.development_type ? item.development_type.title : '-'"></p>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <h6>Completion Status</h6>
                            <p v-html="item.completion_status ? item.completion_status.title : '-'"></p>
                            <h6>Life Cycle</h6>
                            <p v-html="item.life_cycle ? item.life_cycle.title : '-'"></p>
                            <h6>Master Developers</h6>
                            <p v-html="item.contact_names ? item.contact_names : '-'"></p>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <h6>Special Classifications</h6>
                            <p v-html="item.special_classification_titles ? item.special_classification_titles : '-'"></p>
                            <h6>Amenities</h6>
                            <p v-html="item.amenity_titles ? item.amenity_titles : '-'"></p>
                            <h6>Road Locations</h6>
                            <p v-html="item.road_location_titles ? item.road_location_titles : '-'"></p>
                          </v-col>

                          <v-col cols="12" sm="3">
                            <h6>Added By</h6>
                            <p v-html="item.created_user_name ? item.created_user_name + ' at ' + formatDateAndTime(item.created_at) : '-'"></p>
                            <h6>Updated By</h6>
                            <p v-html="item.updated_user_name ? item.updated_user_name + ' at ' + formatDateAndTime(item.updated_at) : '-'"></p>
                            <h6>Featured/Trending</h6>
                            <v-switch
                                v-if="$store.state.auth.user.team_id === 1"
                                v-model="item.is_featured"
                                v-bind:color="item.is_featured === 1 ? 'success': ''"
                                item-value="value"
                                @change="updateStatus($event,item.id,'is_featured')"
                            ></v-switch>
                            <v-switch
                                v-if="$store.state.auth.user.team_id === 2"
                                v-model="item.is_featured_afs"
                                v-bind:color="item.is_featured_afs === 1 ? 'success': ''"
                                item-value="value"
                                @change="updateStatus($event,item.id,'is_featured_afs')"
                            ></v-switch>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <!--                      <v-toolbar-->
                      <!--                          height="30"-->
                      <!--                          dark-->
                      <!--                          color="primary"-->
                      <!--                      >-->
                      <!--                        <v-toolbar-title>-->
                      <!--                          Created at:-->
                      <!--                          {{ formatDateAndTime(item.created_at) }} by-->
                      <!--                          {{ item.created_user_name }}-->
                      <!--                        </v-toolbar-title>-->
                      <!--                        <v-spacer></v-spacer>-->
                      <!--                        <v-toolbar-title>-->
                      <!--                          Updated at:-->
                      <!--                          {{ formatDateAndTime(item.updated_at) }} by-->
                      <!--                          {{ item.updated_user_name }}-->
                      <!--                        </v-toolbar-title>-->
                      <!--                      </v-toolbar>-->
                    </v-card>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <p class="text-center"><i>No Record Found.</i></p>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :options.sync="pagination"
                  :server-items-length="totalRecords"
                  @update:items-per-page="refreshData(false)"
                  :footer-props="{
                    itemsPerPageOptions: rowsPerPageItems,
                    showFirstLastPage: true,
                  }"
                  :loading="loading"
                  v-show="!isLoaded"
                  mobile-breakpoint="100"
                  class="elevation-0 custom-datatable"
              >
                <template v-slot:top="{ pagination, options, updateOptions }">
                  <v-data-footer
                      class="custom-datatable-footer"
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      :items-per-page-options="rowsPerPageItems"
                      :show-first-last-page="true"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
                </template>
              </v-data-table>
              <v-row>
                <v-col cols="12">
                  <v-pagination
                      color="#00004d"
                      :total-visible="15"
                      @input="refreshData(false)"
                      :disabled="loading"
                      v-model="pagination.page"
                      :length="pageCount">
                  </v-pagination>
                </v-col>
              </v-row>
            </v-card>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  DEVELOPMENTS_LIST,
  DEVELOPMENTS_BY_TYPE,
  CREATE_DEVELOPMENTS,
  UPDATE_DEVELOPMENTS,
  CLEAR_DEVELOPMENTS_ERRORS,
  UPDATE_DEVELOPMENTS_STATUS,
  DELETE_DEVELOPMENTS,
  GET_DEVELOPMENTS_TREE,
  GET_DEVELOPMENTS_DETAILS,
  DEVELOPMENTS_SIMPLE_LIST, SYNC_DEVELOPMENT_TRANSACTIONS,
} from "@/core/services/store/developments.module";
import DevelopmentDetails from "@/view/pages/realstate/developments/inc/DevelopmentDetails";
import DevelopmentUnitDetails from "@/view/pages/realstate/developments/inc/DevelopmentUnitDetails";
import SpecialClassificationsSelection from "@/view/pages/realstate/selections/SpecialClassificationsSelection";
import AmenitiesSelection from "@/view/pages/realstate/selections/AmenitiesSelection";
import RoadLocationsSelection from "@/view/pages/realstate/selections/RoadLocationsSelection";
import ModuleMedia from "@/view/pages/realstate/media/ModuleMedia";
import ModuleUploadedFiles from "@/view/pages/realstate/media/ModuleUploadedFiles";
import ModuleLinks from "@/view/pages/realstate/media/ModuleLinks";
import {DEVELOPMENTTYPES_SIMPLE_LIST} from "@/core/services/store/developmenttypes.module";
import {mapState} from "vuex";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {SET_CONFIRMATION} from "@/core/services/store/confirmation.module";
import {COMPLETIONSTATUSES_SIMPLE_LIST} from "@/core/services/store/completion_statuses.module";
import {bus} from "@/main";
import {LIFECYCLES_SIMPLE_LIST} from "@/core/services/store/lifecycles.module";
import {SPECIALCLASSIFICATIONS_SIMPLE_LIST} from "@/core/services/store/specialclassifications.module";
import {AMENITIES_SIMPLE_LIST} from "@/core/services/store/amenities.module";
import {ROADLOCATIONS_SIMPLE_LIST} from "@/core/services/store/road_locations.module";
import {CONTACTS_SIMPLE_LIST} from "@/core/services/store/contacts.module";
import ProjectUnitDetails from "@/view/pages/realstate/projects/inc/ProjectUnitDetails";
import Swal from "sweetalert2";

export default {
  name: 'master-developments',
  components: {
    ProjectUnitDetails,
    ModuleMedia,
    ModuleUploadedFiles,
    SpecialClassificationsSelection,
    RoadLocationsSelection,
    AmenitiesSelection,
    DevelopmentDetails,
    DevelopmentUnitDetails,
    ModuleLinks
  },
  data() {
    return {
      mapInput: '',
      center: {},
      locationMarkers: [],
      existingPlace: null,
      headers: [
        {
          text: 'Commercial title',
          value: 'commercial_title',
          name: 'commercial_title',
          filterable: true,
          sortable: false
        },
        {text: 'Country', value: 'country.title', name: 'country.title', filterable: false, sortable: false},
        {text: 'City', value: 'city.title', name: 'city.title', filterable: false, sortable: false},
        {
          text: 'Development Type',
          value: 'development_type.title',
          name: 'development_type.title',
          filterable: false,
          sortable: false
        },
        {text: "Created", value: "created_at", name: "created_at", filterable: false, sortable: true},
        {text: "Updated", value: "updated_at", name: "updated_at", filterable: false, sortable: false},
        {text: 'Actions', value: 'actions', name: 'actions', filterable: false, sortable: false},
      ],
      isLoaded: true,
      isLoadedContacts: false,
      draw: 1,
      searchQuery: "",
      loading: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
        sortDesc: "desc"
      },
      totalRecords: 0,
      rowsPerPageItems: [25, 50, 100, 150, 200],
      headerindex: "",
      developmentTypesFilter: [],
      completionStatusesFilter: [],
      lifeCyclesFilter: [],
      contactsFilter: [],
      countriesFilter: [],
      rlFilter: [],
      rlIdsFilter: [],
      isContactsLoaded: false,
      specialClassificationsFilter: [],
      specialClassificationsIdsFilter: [],
      amenitiesFilter: [],
      amenitiesIdsFilter: [],
      developmentsFilter: [],
      isDevelopmentsLoaded: false,
      columns: [],
      validationRules: {
        valid: true,
        titleRules: [
          v => !!v || "This field is required",
          v => (v && v.length >= 3) || "Must be greater than 3 characters",
          v => (v && v.length <= 100) || "Must be less than 100 characters"
        ],
        cityRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select City';
            else return true;
          }
        ],
        contactIdRules: [
          v => {
            if (!v || v.length < 1) return "Select Contact";
            else return true;
          }
        ],
        developmentIdRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Development';
            else return true;
          }
        ],
        developmentTypeIdRules: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Development Type';
            else return true;
          }
        ],
        statusIdRule: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Status';
            else return true;
          }
        ],
        yearOfLanchRule: [
          (v) => {
            if (!v || v.length < 1)
              return 'Select Launching Date';
            else return true;
          }
        ],
        commercialRules: [
          v => !!v || "Commercial Name is required",
          v => (v && v.length >= 3) || "Name must be greater than 3 characters",
          v => (v && v.length <= 30) || "Name must be less than 30 characters"
        ],
        typeRules: [
          v => !!v || "Type is required",
          v => (v && v.length >= 3) || "Type must be greater than 3 characters",
          v => (v && v.length <= 30) || "Type must be less than 30 characters"
        ],
        multipleRules: [
          v => {
            if (!v || v.length < 1) return "Select Master Developer";
            else return true;
          }
        ]
      },
      items: [],
      dialog: false,
      editedItem: {},
      showUploadedFiles: false,
      moveDialog: false,
      msdDialog: false,
      projectsFilterToMove: [],
      projectIdsFilterToMove: [],
      projectIdsToMove: [],
      itemToMsd: '',
      itemFromMsd: {},
      itemToMove: '',
      itemFromMove: {},
      treeDialog: false,
      treeItem: {},
      searchFormData: {
        otSearch: '',
        ctSearch: '',
        mdSearch: '',
        ftSearch: '',
        developmentTypeSearch: '',
        completionStatusSearch: ''
      },
    }
  },
  watch: {
    // params: {
    //   handler() {
    //     this.loadData().then(response => {
    //       this.items = response.items;
    //       this.totalRecords = response.total;
    //     });
    //   },
    //   deep: true
    // },
    dialog(val) {
      !val && this.$store.dispatch(CLEAR_DEVELOPMENTS_ERRORS);
      if (!val) {
        let that = this;
        setTimeout(function () {
          that.editedItem.description = '';
        }, 500)
      }
      if (!val) this.editedItem = {};
      if (!val) this.showUploadedFiles = false;
      if (!val) this.center = {lat: 0, lng: 0};
      if (!val) this.locationMarkers = [];
      if (!val) this.mapInput = '';
      if (!val) this.activeTab = 0;
      if (!val) this.$store.state.developments.latitude = '';
      if (!val) this.$store.state.developments.longitude = '';
      if (!val) this.$store.state.selections.selectedSCIds = []
      if (!val) this.$store.state.selections.selectedAmenityIds = []
      if (!val) this.$store.state.selections.selectedRLIds = []
      bus.$emit("moduleFilesDeletedByModal");
      bus.$emit("moduleLinksDeletedByModal");
    },
    treeDialog(val) {
      if (!val) this.treeItem = {};
    }
  },
  mounted() {
    for (var i = 0; i < this.headers.length; i++) {
      this.columns.push(
          '&columns[' + i + '][data]=' + this.headers[i].value +
          '&columns[' + i + '][name]=' + this.headers[i].name +
          '&columns[' + i + '][searchable]=' + this.headers[i].filterable +
          '&columns[' + i + '][orderable]=' + this.headers[i].sortable +
          '&columns[' + i + '][search][value]=' +
          '&columns[' + i + '][search][regex]=' + false
      );
    }
    this.loadData().then(response => {
      this.items = response.items;
      this.totalRecords = response.total;
      this.getDevelopmentTypes();
    });
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Development's Management", route: "master-developments"},
      {title: "Master Developments"}
    ]);
    if (this.$route.query.hasOwnProperty('addModule')) {
      this.dialog = true;
    }
    if (this.$route.query.hasOwnProperty('development')) {
      let development = parseInt(this.$route.query.development)
      let item = {id: development}
      this.showTreeView(item)
    }
  },
  methods: {
    initMarker(loc) {
      this.existingPlace = loc;
    },
    addLocationMarker() {
      if (this.existingPlace) {
        this.locationMarkers = [];
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng()
        };
        this.$store.state.developments.latitude = this.existingPlace.geometry.location.lat();
        this.$store.state.developments.longitude = this.existingPlace.geometry.location.lng();
        this.editedItem.location = this.existingPlace.formatted_address;
        this.mapInput = '';
        // this.editedItem.latitude = this.existingPlace.geometry.location.lat();
        // this.editedItem.longitude = this.existingPlace.geometry.location.lng();
        this.locationMarkers.push({position: marker});
        this.center = marker;
        this.existingPlace = null;
      }
    },
    setLatLong(e) {
      this.existingPlace = null;
      this.mapInput = '';
      let latLng = e.latLng.toJSON()
      this.$store.state.developments.latitude = latLng.lat;
      this.$store.state.developments.longitude = latLng.lng;
      const marker = {
        lat: latLng.lat,
        lng: latLng.lng
      };
      this.locationMarkers = [{position: marker}];
      this.center = marker;
    },
    searchQueryData: function (e) {
      if (e.keyCode === 13) {
        this.searchQuery = e.target.value;
      }
    },
    getHeaderIndex(name) {
      let that = this;
      this.headers.filter(function (ele, i) {
        if (name == ele.name) {
          that.headerindex = i;
        }
      });
    },
    loadData() {
      this.loading = true;
      this.isLoaded = true;
      this.items = [];
      this.mainDataTableSelection = [];
      return new Promise((resolve) => {
        let params = this.params;
        params.length = params.itemsPerPage; //set how many records to fetch per page
        params.start =
            params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
        params.draw = this.draw;
        let query = Object.keys(params).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
        }).join('&');
        let searchFormDataQuery = Object.keys(this.searchFormData).map((key) => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(this.searchFormData[key])
        }).join('&');
        this.getHeaderIndex(params.sortBy[0]);
        query += '&' + searchFormDataQuery
        query +=
            "&search[value]=" +
            params.query +
            "&search[regex]=" +
            false +
            "&order[0][column]=" +
            (params.sortBy.length === 0 ? 4 : this.headerindex) +
            "&order[0][dir]=" +
            (params.sortDesc[0] ? "desc" : "asc");
        this.$store.dispatch(DEVELOPMENTS_LIST, {type: '0', q: query, columns: this.columns}).then((res) => {
          // this.draw++;
          let items = res.records.data;
          let total = res.records.recordsFiltered;
          this.loading = !this.loading;
          this.isLoaded = !this.isLoaded;
          resolve({
            items,
            total
          });
        });
      });
    },
    refreshData(loadDep = true) {
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
        if (loadDep) this.getDevelopmentTypes();
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    showEditDialog(item) {
      this.$store.dispatch(GET_DEVELOPMENTS_DETAILS, item.id).then((data) => {
        let cityArr = [];
        this.editedItem = data.records || {};
        this.mapInput = '';
        this.$store.state.developments.latitude = data.records.latitude ? parseFloat(data.records.latitude) : 0;
        this.$store.state.developments.longitude = data.records.longitude ? parseFloat(data.records.longitude) : 0;
        const marker = {
          lat: data.records.latitude ? parseFloat(data.records.latitude) : 0,
          lng: data.records.longitude ? parseFloat(data.records.longitude) : 0
        };
        this.locationMarkers.push({position: marker});
        this.center = marker;
        // if (data.records.city) {
        //   cityArr.push(data.records.city)
        //   this.citiesFilter = cityArr;
        // }
        this.contactsFilter = data.records.contacts;
        this.editedItem.description = data.records.description ? data.records.description : '';
        this.$store.state.selections.selectedSCIds = this.editedItem.special_classification_ids
        this.$store.state.selections.selectedAmenityIds = this.editedItem.amenity_ids
        this.$store.state.selections.selectedRLIds = this.editedItem.road_location_ids
        this.dialog = true;
        this.showUploadedFiles = true;
      });
    },
    closeDialog() {
      this.dialog = !this.dialog;
    },
    saveItem(item) {
      if (this.$refs.form.validate()) {
        let method = CREATE_DEVELOPMENTS;
        let id = item.id;
        let data = {
          data: item
        };
        this.$set(item, "development_files", this.$store.state.uploads.uploadedStateOtherFiles);
        this.$set(item, "development_links", this.$store.state.uploads.uploadedStateLinks);
        this.$set(item, "type", '0');
        this.$set(item, "latitude", this.$store.state.developments.latitude);
        this.$set(item, "longitude", this.$store.state.developments.longitude);
        this.$set(item, "special_classification_ids", this.$store.state.selections.selectedSCIds);
        this.$set(item, "amenity_ids", this.$store.state.selections.selectedAmenityIds);
        this.$set(item, "road_location_ids", this.$store.state.selections.selectedRLIds);
        if (id) {
          method = UPDATE_DEVELOPMENTS;
          // delete data.data.id;
        }
        this.$store.dispatch(CLEAR_DEVELOPMENTS_ERRORS);
        this.$store.dispatch(method, {'slug': id, data: data.data}).then(() => {
          // if (method === 'update_developments') {
          //   this.showUploadedFiles = false;
          //   bus.$emit("moduleFilesDeletedByModal");
          //   bus.$emit("moduleLinksDeletedByModal");
          //   this.showEditDialog(item);
          // } else {
          //   this.dialog = false;
          // }
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
          // this.editedItem = res.records;
          this.dialog = !this.dialog;
          if (this.$route.query.hasOwnProperty('addModule')) {
            window.close();
          }
        })
      }
    },
    deleteItem(item) {
      let that = this;
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to archive '" + item.commercial_title + "'?",
        clr: 'red',
        callback: function () {
          let id = item.id;
          this.$store.dispatch(DELETE_DEVELOPMENTS, {
            'id': id
          }).then(() => {
            that.loadData().then(response => {
              that.items = response.items;
              that.totalRecords = response.total;
            });
          });
        }
      });
    },
    syncDevelopmentTransactions(item) {
      this.$store.dispatch(SET_CONFIRMATION, {
        title: "Are you sure?",
        msg: "You want to sync transactions and advertisements?",
        clr: 'primary',
        callback: function () {
          let id = item.id;
          this.$store.dispatch(SYNC_DEVELOPMENT_TRANSACTIONS, {
            'development_id': id
          }).then((data) => {
            Swal.fire('', data._metadata.message)
          });
        }
      });
    },
    getDevelopmentTypes(loadNext = true) {
      this.$store.dispatch(DEVELOPMENTTYPES_SIMPLE_LIST, {
        type: 'Master Developments',
        is_property: 0
      }).then((data) => {
        this.developmentTypesFilter = data.records.developmentTypesList;
        if (loadNext) this.getCompletionStatuses()
      });
    },
    getCompletionStatuses(loadNext = true) {
      this.$store.dispatch(COMPLETIONSTATUSES_SIMPLE_LIST, {type: 'Master Developments'}).then((data) => {
        this.completionStatusesFilter = data.records.completionStatusesList;
        if (loadNext) this.getLifeCycles()
      });
    },
    getLifeCycles(loadNext = true) {
      this.$store.dispatch(LIFECYCLES_SIMPLE_LIST, {type: 'Master Developments'}).then((data) => {
        this.lifeCyclesFilter = data.records.lifeCyclesList;
        if (loadNext) this.getSpecialClassifications()
      });
    },
    getSpecialClassifications(loadNext = true) {
      this.$store.dispatch(SPECIALCLASSIFICATIONS_SIMPLE_LIST, {type: 'Master Developments'}).then((data) => {
        this.specialClassificationsFilter = data.records.specialClassificationsList;
        let that = this;
        this.specialClassificationsFilter.forEach(function (v) {
          if (v.children.length > 0) {
            v.children.forEach(function (vv) {
              that.specialClassificationsIdsFilter.push(vv.id)
            })
          }
        })
        if (loadNext) this.getAmenities()
      });
    },
    getAmenities(loadNext = true) {
      this.$store.dispatch(AMENITIES_SIMPLE_LIST, {type: 'Master Developments'}).then((data) => {
        this.amenitiesFilter = data.records.amenitiesList;
        let that = this;
        this.amenitiesFilter.forEach(function (v) {
          if (v.children.length > 0) {
            v.children.forEach(function (vv) {
              that.amenitiesIdsFilter.push(vv.id)
            })
          }
        })
        if (loadNext) this.getRoadLocations()
      });
    },
    getRoadLocations() {
      this.$store.dispatch(ROADLOCATIONS_SIMPLE_LIST, {type: 'Master Developments'}).then((data) => {
        this.rlFilter = data.records.roadLocationsList;
        let that = this;
        this.rlFilter.forEach(function (v) {
          if (v.children.length > 0) {
            v.children.forEach(function (vv) {
              that.rlIdsFilter.push(vv.id)
            })
          }
        })
      });
    },
    contactsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isContactsLoaded = true;
          that.$store
              .dispatch(CONTACTS_SIMPLE_LIST, {
                role: 'Master Developers',
                q: e.target.value
              })
              .then(data => {
                that.contactsFilter = that.contactsFilter.concat(data.records.contactsList);
                that.isContactsLoaded = false;
              });
        }
      }, 900);
    },
    showTreeView(item, type = 'full') {
      if (type === 'projects') {
        this.$store.dispatch(GET_DEVELOPMENTS_TREE, {id: item.id, type: type}).then((data) => {
          this.projectsFilterToMove = data.records.projects
          this.projectIdsFilterToMove = data.records.projectIds
          this.itemFromMove = item;
          this.moveDialog = true;
        });
      } else {
        this.$store.dispatch(GET_DEVELOPMENTS_TREE, {id: item.id, type: type}).then((data) => {
          this.treeItem = data.records || {};
          this.treeDialog = !this.treeDialog;
        });
      }
    },
    updateStatus(status, id, column) {
      this.$store.dispatch(CLEAR_DEVELOPMENTS_ERRORS);
      let data = {
        status: status,
        column: column
      };
      if (column === 'type' || column === 'msd') {
        let that = this;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?",
          msg: "You want to make it Sub Development?",
          clr: 'primary',
          callback: function () {
            that.$store.dispatch(UPDATE_DEVELOPMENTS_STATUS, {'slug': id, data: data}).then(() => {
              if (column === 'msd') {
                that.msdDialog = false
                that.itemToMsd = ''
                that.itemFromMsd = {}
              }
              that.loadData().then(response => {
                that.items = response.items;
                that.totalRecords = response.total;
              });
            });
          }
        });
      } else if (column === 'make_project') {
        let that = this;
        this.$store.dispatch(SET_CONFIRMATION, {
          title: "Are you sure?", msg: "You want to make it Project?", clr: 'primary', callback: function () {
            that.$store.dispatch(UPDATE_DEVELOPMENTS_STATUS, {'slug': id, data: data}).then(() => {
              that.loadData().then(response => {
                that.items = response.items;
                that.totalRecords = response.total;
              });
            });
          }
        });
      } else {
        this.$store.dispatch(UPDATE_DEVELOPMENTS_STATUS, {'slug': id, data: data}).then(() => {
          if (column === 'move_projects') {
            this.moveDialog = false
            this.itemToMove = ''
            this.projectIdsToMove = []
            this.itemFromMove = {}
          }
          this.loadData().then(response => {
            this.items = response.items;
            this.totalRecords = response.total;
          });
        });
      }
    },
    developmentsFilterKeyUp(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isDevelopmentsLoaded = true;
          that.$store
              .dispatch(DEVELOPMENTS_BY_TYPE, {
                q: e.target.value,
                type: '0'
              })
              .then(data => {
                that.developmentsFilter = data.records.developmentsList;
                that.isDevelopmentsLoaded = false;
              });
        }
      }, 900);
    },
    developmentsFilterKeyUpWT(e) {
      clearTimeout(this.apiTimeout)
      let that = this
      this.apiTimeout = setTimeout(function () {
        if (e.target.value.length >= 3) {
          that.isDevelopmentsLoaded = true;
          that.$store
              .dispatch(DEVELOPMENTS_SIMPLE_LIST, {
                q: e.target.value
              })
              .then(data => {
                that.developmentsFilter = data.records.developmentsList;
                that.isDevelopmentsLoaded = false;
              });
        }
      }, 900);
    },
    hitAdvanceSearch(reset = false) {
      this.pagination.page = 1
      if (reset) {
        this.searchFormData = {
          otSearch: '',
          ctSearch: '',
          mdSearch: '',
          ftSearch: '',
          developmentTypeSearch: '',
          completionStatusSearch: ''
        };
      }
      this.loadData().then(response => {
        this.items = response.items;
        this.totalRecords = response.total;
      });
    },
    editDevelopmentFD(item) {
      this.showEditDialog(item)
    }
  },
  computed: {
    params() {
      return {
        ...this.pagination,
        query: this.searchQuery
      };
    },
    ...mapState({
      errors: state => state.developments.errors
    }),
    pageCount() {
      // return this.totalRecords / this.pagination.rowsPerPage
      return this.params.itemsPerPage <= this.totalRecords ? Math.ceil(this.totalRecords / this.params.itemsPerPage) : 0
    }
  }
};
</script>